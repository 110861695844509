import { QueryHookOptions } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { useEffect } from "react";
import { useStore } from "../store/store";
import { $, MapType, Query } from "./graphql-zeus";
import { query, useTypedQuery } from "./hooks";

export const ME = query({
  me: {
    "...on Customer": {
      id: true,
    },
    "...on Rider": {
      id: true,
    },
    "...on RestaurantAdmin": {
      id: true,
      name: true,
      roles: true,
      email: true,
      restaurants: {
        id: true,
        name: true,
        headerImageKey: true,
        address: {
          id: true,
          address_line_1: true,
          town: true,
        },
      },
    },
  },
});

export const useMe = (
  options?: QueryHookOptions<MapType<Query, typeof ME>, Record<string, any>>
) => {
  const { data, loading, error } = useTypedQuery(ME, options);

  const me = data && data.me && "name" in data.me ? data.me : null;

  useEffect(() => {
    if (me?.email) {
      Sentry.setUser({
        id: me.id,
        email: me.email,
      });
    }
  }, [me?.email]);

  return { data, me, loading, error };
};

export const RESTAURANT = query({
  Restaurant: [
    {
      id: $`id`,
    },
    {
      id: true,
      name: true,
      ratingCount: true,
      ratingStars: true,
      slug: true,
      allergyWarnings: true,
      acceptsScheduledOrders: true,
      basePrepTime: true,
      address: {
        id: true,
        address_line_1: true,
        town: true,
        postcode: true,
        coordinates: {
          coordinates: true,
        },
      },
      open: true,
      openingTimes: {
        id: true,
        close: true,
        daysOfWeek: true,
        open: true,
      },
      zone: {
        id: true,
        slug: true,
        name: true,
      },
      fulfillmentMethods: true,
      commission: true,
      commissionCollection: true,
      busyStatus: true,
      online: true,
      isOnVacation: true,
      tags: {
        id: true,
        name: true,
        type: true,
        image: true,
      },
      description: true,
      menuId: true,
      exclusive: true,
    },
  ],
});

export const useSelectedRestaurant = (options: QueryHookOptions<any> = {}) => {
  const { me } = useMe();
  const id = useStore((s) => s.selectedRestaurantId);

  const q = useTypedQuery(RESTAURANT, {
    skip: !id,
    variables: {
      id,
    },
    ...options,
  });

  return q;
};

export const MENU = query({
  Menu: [
    {
      id: $`id`,
    },
    {
      id: true,
      name: true,
      categories: {
        id: true,
        name: true,
        description: true,
        topLevel: true,
        sortOrder: true,
        items: {
          id: true,
          available: true,
          approved: true,
          headerImageKey: true,
          sortOrder: true,
          name: true,
          description: true,
          ingredients: true,
          published: true,
          unitPrice: true,
          vegan: true,
          alcohol: true,
          categoryId: true,
          headerImage: true,
          modifiers: {
            id: true,
          },
        },
        times: {
          id: true,
          open: true,
          close: true,
          daysOfWeek: true,
        },
      },
      modifierGroups: {
        id: true,
        name: true,
        instruction: true,
        maxOptions: true,
        maxPerOption: true,
        minOptions: true,
        cascades: true,
        sortOrder: true,
        priceStrategy: true,
        options: {
          id: true,
          name: true,
          available: true,
          description: true,
          unitPrice: true,
          categoryId: true,
          headerImage: true,
          sortOrder: true,
          published: true,
        },
      },
    },
  ],
});

export const useSelectedMenu = () => {
  const { data } = useSelectedRestaurant();

  const menu = useTypedQuery(MENU, {
    skip: !data?.Restaurant.menuId,
    variables: {
      id: data?.Restaurant.menuId,
    },
  });

  return menu;
};

export const ORDER_LIST = query({
  OrderList: [
    {
      sortBy: $`sortBy`,
      order: $`order`,
      skip: $`skip`,
      take: $`take`,
      customerId: $`customerId`,
      status: $`status`,
      zoneId: $`zoneId`,
      riderId: $`riderId`,
      query: $`query`,
      restaurantId: $`restaurantId`,
      fulfillmentMethods: $`fulfillmentMethods`,
    },
    {
      total: true,
      hasMore: true,
      items: {
        id: true,
        number: true,
        placedAt: true,
        pickupAt: true,
        status: true,
        cancellationReason: true,
        riderEarnings: true,
        isBeingOfferedToRider: true,
        isRiderAtRestaurant: true,
        baseRiderEarnings: true,
        riderPickedUpTime: true,
        pickupBlocked: true,
        amountRefunded: true,
        fulfillmentMethod: true,
        deliveryEstimate: true,
        riderArrivedAtRestaurantTime: true,
        riderArrivedAtCustomerTime: true,
        riderDroppedOffTime: true,
        deliveryEstimateMax: true,
        restaurant: {
          id: true,
          name: true,
        },
        basket: {
          id: true,
          location: {
            coordinates: true,
          },
          scheduledDelivery: true,
          targetDeliveryFromNow: true,
          restaurantId: true,
          riderTip: true,
          subtotal: true,
          deliveryFee: true,
          sessionId: true,
          totalPrice: true,
          totalPriceBeforeDiscount: true,
        },
        rider: {
          name: true,
        },
        b2bDeliveryJobId: true,
        b2bDeliveryJob: {
          id: true,
        },
      },
    },
  ],
});

export const ORDER = query({
  order: [
    {
      id: $`id`,
    },
    {
      id: true,
      number: true,
      status: true,
      fulfillmentMethod: true,
      isBeingOfferedToRider: true,
      isRiderAtRestaurant: true,
      riderArrivedAtCustomerTime: true,
      riderArrivedAtRestaurantTime: true,
      placedAt: true,
      pickupAt: true,

      restaurant: {
        id: true,
        name: true,
      },
      rider: {
        id: true,
        name: true,
      },
      basket: {
        id: true,
        subtotal: true,
        items: {
          id: true,
          menuItemId: true,
          totalPrice: true,
          name: true,
          quantity: true,
          modifierItems: {
            id: true,
            name: true,
            menuItemId: true,
            quantity: true,
            modifierGroupId: true,
          },
        }
      },
    },
  ],
});

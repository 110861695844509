import create from "zustand";
import { configurePersist } from "zustand-persist";

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

const { persist, purge } = configurePersist({
  storage: (process as any).browser ? localStorage : createNoopStorage(), // use `AsyncStorage` in react native
  rootKey: "ecoeats", // optional, default value is `root`
});

export type Store = {
  selectedRestaurantId: string;
  setSelectedRestaurantId: (id: string) => void;

  acceptingLiveOrders: boolean;
  setAcceptingLiveOrders: (accepting: boolean) => void;

  darkMode: boolean;
  toggleDark: (darkMode: boolean) => void;
};

export const useStore = create<Store>(
  persist(
    { key: "settings", allowlist: ["selectedRestaurantId"] },
    (set, get) => ({
      selectedRestaurantId: "",
      setSelectedRestaurantId: (id) =>
        set({ ...get(), selectedRestaurantId: id }),
      acceptingLiveOrders: false,
      setAcceptingLiveOrders: (v) => set({ ...get(), acceptingLiveOrders: v }),
      darkMode: false,
      toggleDark: (darkMode) => set({ darkMode }),
    })
  )
);
